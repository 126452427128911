<template>
  <specialties-component />
</template>

<script>
import SpecialtiesComponent from "@/components/admin/catalogs/SpecialtiesComponent";

export default {
  name: "Specialties",
  title: "Especialidades | Baja California Health Tourism",
  components: { SpecialtiesComponent },
};
</script>

<style scoped></style>
